<template>
  <div :id="el" class="char-bar full-width full-height"></div>
</template>

<script>
import echarts from 'echarts';
export default {
  name: 'ChartBarRunCom',
  props: {
    el: {
      type: String,
    },
    data: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    data: {
      handler() {
        this.$nextTick(() => {
          this.renderCharBar();
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.renderCharBar();
    });
  },
  methods: {
    renderCharBar() {
      let obj = document.getElementById(this.el); //"abnormal-chart"
      if (!obj) {
        return false;
      }
      /*  console.error('hah-----------', this.data); */
      let dataArr = [];
      if (this.data.data && this.data.data.length > 0) {
        dataArr = this.data.data.map((item) => {
          return {
            value: item.value,
            // name: `{a|${item.name} }{b|${item.value} }`,
            name: item.name,
          };
        });
      }
      //console.log("pieChartData=>", pieChartData, pieChartDataTotal);
      let myChart = echarts.init(obj);
      //var that = this;
      // 绘制图表
      myChart.setOption({
        tooltip: {
          show: true,
          trigger: 'item',
          /* formatter: '{a} <br/>{b}: {c} ({d}%)' ,*/
        },
        legend: {
          show: true,
          orient: 'vertical',
          right: '26%',
          top: 'center',
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            color: 'rgba(134, 179, 233, 1)',
            rich: {
              a: {
                color: '#86B3E9',
                width: 40,
                fontSize: 12,
              },
              b: {
                color: '#86B3E9',
                fontSize: 12,
                width: 40,
              },
              c: {
                fontSize: 12,
                width: 40,
                color: '#fff',
              },
            },
          },
        },
        title: {
          text: [`{a|${this.data.name}}`, `{b|${this.data.count}}`].join('\n'),
          show: this.data.showTitle,
          left: '22%',
          top: 'center',
          textAlign: 'center',
          textStyle: {
            rich: {
              a: {
                color: '#86B3E9',
                fontSize: 12,
                lineHeight: 30,
              },
              b: {
                color: '#fff',
                fontSize: 18,
                lineHeight: 20,
              },
            },
          },
          triggerEvent: true,
        },
        grid: {
          top: '0px',
          left: '0px',
          right: '0',
          bottom: '0px',
        },
        series: [
          {
            type: 'pie',
            radius: ['68%', '86%'],
            x: 'center',
            label: {
              show: false,
            },
            top: '5%',
            left: '5%',
            right: '60%',
            bottom: '5%',
            labelLine: {
              show: false,
            },
            // color: ['#3a689f', '#c4b9b9', '#ee8987'],
            color: [
              '#3a689f',
              '#c4b9b9',
              '#ee8987',
              '#ffcc66',
              '#ff0066',
              '#660066',
              '#006666',
              '#00ff99',
            ],
            data: dataArr /* [
              {
                value: 400,
                name: '{a|正常 }{b|400 }{c|40}',
                itemStyle: {
                  color: '#3a689f',
                },
              },
              {
                value: 300,
                name: '{a|异常 }{b|300 }{c|30}',
                itemStyle: {
                  color: '#ee8987',
                },
              },
              {
                value: 200,
                name: '{a|离线 }{b|200 }{c|30  }',
                itemStyle: {
                  color: '#c4b9b9',
                },
              },
            ], */,
          },
        ],
      });
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
  },
};
</script>

<style scoped></style>
